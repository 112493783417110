
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";


export default defineComponent({
  name: 'LookupSingleLevel',
  components: {
    Dialog,
    Button,
    ProgressSpinner,
    DataTable,
    Column,
  },
  props: {
    partNumber: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      default: ''
    }
  },
  emits: ['onClose'],
  
  updated() {
    this.part = this.partNumber
    this.visible = this.show
    if (this.visible) {
      // this.isLoading = true
    }
  },
  data() {
    return {
      isLoading: false,
      part: this.partNumber,
      visible: false,
      invloc_items: [] as any[],
      cost_group_items: [] as any[],
    }
  },

  computed: {
    ...mapGetters({
      client: 'session/getClient'
    }),
    getTitle() {
      if (this.header) {
        return this.header
      }
      return `Single Level for "${this.part}"`
    },

    getTableInfo() {
      const info = [] as any[];
      this.invloc_items.forEach((item: any) => {

        info.push({
          invloc: item.invloc || '',
          group: '-',
          on_hand: item.on_hand || '0',
          commited: item.commited || '-',
          allocated: item.allocated || '-',
          shortages: item.shortages || '-',
          available: item.available || '-',
          on_order: item.on_order || '-',
        })
      })
      return info
    }
  },

  methods: {
    handleClose() {
      this.visible = false
      this.$emit('onClose')
    },

  }
})

