
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber'
import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";

export default defineComponent({
  name: 'AddRepDialog',
  components: {
    Button,
    Dialog,
    InputNumber,
    ContactAutocomplete,
  },

  emits: ['addRep'],

  methods: {
    addRecord() {
      this.$emit('addRep', {...this.newRep, li_comms: this.newRep.li_comms.toString(), rep_name: this.selectedName});
      this.visible = false;
      this.newRep = {
        li_reps: '',
        li_comms: 0
      }
    },

    cancel() {
      this.visible = false;
      this.newRep = {
        li_reps: '',
        li_comms: 0
      }
    },

    handleContactSelected(event: any) {
      this.newRep.li_reps = event.first_name[0] + event.last_name[0] ?? '';
      this.selectedName = event.first_name + ' ' + event.last_name;
    },
  },

  data() {
    return {
      visible: false,
      newRep: {
        li_reps: '',
        li_comms: 0
      },
      selectedId: "",
      selectedName: ""
    };
  }
});
